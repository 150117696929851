import { Routes, RouterModule } from '@angular/router';
//Route for content layout with sidebar, navbar and footer .

export const Full_ROUTES: Routes = [
  {
    path: '',
    loadChildren: './dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'santral',
    loadChildren: './operatorpanel/operatorpanel.module#OperatorpanelModule'
  },
  {
    path: 'contacts/contacts',
    loadChildren: './contacts/list/listcontact.module#ListContactModule'
  },
  {
    path: 'contacts/groups',
    loadChildren: './contacts/groups/contactgroups.module#ContactGroupsModule'
  },
  {
    path: 'contacts/new',
    loadChildren: './contacts/new/newcontact.module#NewContactModule'
  },
  {
    path: 'contacts/newGroup',
    loadChildren: './contacts/newGroup/newgroup.module#NewGroupModule'
  },
  {
    path: 'contacts/import',
    loadChildren: './contacts/importContact/contactimport.module#ContactImportModule'
  },
  {
    path: 'contacts/export',
    loadChildren: './contacts/exportContact/contactexport.module#ContactExportModule'
  },
  {
    path: 'contacts/export',
    loadChildren: './contacts/exportContact/contactexport.module#ContactExportModule'
  },
  {
    path: 'sms',
    loadChildren: './sms/sms-components.module#SMSComponentsModule'
  },
  {
    path: 'central',
    loadChildren: './central/central-components.module#CentralComponentsModule'
  },
  {
    path: 'reports/advanced',
    loadChildren: './advanced-reports/advanced-reports-components.module#AdvancedReportsComponentsModule'
  },
  {
    path: 'cdrrecords',
    loadChildren: './cdrrecords/cdrrecords.module#CdrrecordsModule'
  },
  {
    path: 'missedcalls',
    loadChildren: './missedcalls/missedcalls.module#MissedcallsModule'
  },
  {
    path: 'error',
    redirectTo: '/pages/error'
  },
  {
    path: 'login',
    redirectTo: '/pages/login'
  },
  {
    path: 'invoices',
    loadChildren: './invoices/unpaid-invoices/unpaid-invoices.module#UnpaidInvoicesModule'
  },
  {
    path: 'payment',
    loadChildren: './invoices/paid-invoices/paid-invoices.module#PaidInvoicesModule'
  },
  {
    path: 'numbers/numbers',
    loadChildren: './numbers/numbers/numbers.module#NumbersModule'
  },
  {
    path: 'numbers/callhistory',
    loadChildren: './numbers/callhistory/callhistory.module#CallhistoryModule'
  },
  {
    path: 'numbers/numbers',
    loadChildren: './numbers/numbers/numbers.module#NumbersModule'
  },
  {
    path: 'numbers/callhistory',
    loadChildren: './numbers/callhistory/callhistory.module#CallhistoryModule'
  },
  {
    path: 'fax/send',
    loadChildren: './fax/send/faxsend.module#FaxSendModule'
  },
  {
    path: 'fax/list',
    loadChildren: './fax/list/faxlist.module#FaxListModule'
  },
  {
    path: 'fax/queue',
    loadChildren: './fax/queue/faxqueue.module#FaxQueueModule'
  },
  {
    path: 'fax/settings',
    loadChildren: './fax/settings/faxsettings.module#FaxSettingsModule'
  },
  {
    path: 'crmdetails',
    loadChildren: './comingsoon/comingsoon.module#ComingsoonModule'
  },
  {
    path: 'internet',
    loadChildren: './comingsoon/comingsoon.module#ComingsoonModule'
  },
  {
    path: 'agentlogin',
    loadChildren: './comingsoon/comingsoon.module#ComingsoonModule'
  },
  {
    path: 'callcenter/reports',
    loadChildren: './comingsoon/comingsoon.module#ComingsoonModule'
  },
  {
    path: 'callcenter/reports',
    loadChildren: './documents/documents.module#DocumentsModule'
  },
  {
    path: 'support',
    loadChildren: './support/support-components.module#SupportComponentsModule'
  },
  {
    path: 'documents',
    loadChildren: './documents/documents.module#DocumentsModule'
  },
  {
    path: 'api',
    loadChildren: './api/api-components.module#ApiComponentsModule'
  },
  {
    path: 'profile',
    loadChildren: './profile/profile-components.module#ProfileComponentsModule'
  },
];
