import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { WebsocketService } from '../../_service/websocket.service';
import { ChatService } from '../../_service/chat.service';
import { Observable, Subscription } from 'rxjs/Rx';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import swal from 'sweetalert2';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss'],
  providers: [ WebsocketService, ChatService ]
})

export class NotificationSidebarComponent implements OnInit {
  private my_timer;
  acc:                              any;
  public extensions:                any = [];
  public queues:                    any = [];
  public activeCalls:               any = {};
  public callKeys:                  any   = [];
  deneme:                           any = 0;
  givewarning:                      any = [];
  queueSettings:                    any = {};
  generalSettings:                  any = { "notifications": { "status": true, "sound": true, "lastNotificationTime": 500, "notificationDelay": 60 } };
  lastNotificationTime:             any = 5;

  constructor( private chatService: ChatService, private ref: ChangeDetectorRef ) {
    chatService.messages.subscribe( this.eventProcess.bind(this) );
  }

  ngOnInit() {
    $.getScript('./assets/js/notification-sidebar.js');
    this.my_timer = Observable.timer(2000,1000);
  }

  getTimer( queueid, callid ) {

    this.my_timer.subscribe(t=> {
      if ( this.queues[ queueid ].calls.length < 1 ) {
        this.givewarning[queueid].class = false;
      } else {
        for ( let c of this.queues[ queueid ].calls ) {
          if ( this.generalSettings.notifications.status ) {
            if ( typeof this.queueSettings[ this.queues[ queueid ].name ] != "undefined" ) {
              let settings = this.queueSettings[ this.queues[ queueid ].name ];
              if ( settings.notifications.status && ( c.wait >= settings.notifications.waittime || this.queues[ queueid ].calls.length >= settings.notifications.maxpeople ) ) {
                  this.givewarning[queueid].class = true;
                  if ( this.generalSettings.notifications.sound && this.generalSettings.notifications.lastNotificationTime >= this.generalSettings.notifications.notificationDelay ) {
                    this.playSound();
                    this.generalSettings.notifications.lastNotificationTime = 0;
                  }
                  break;
              } else {
                  this.givewarning[queueid].class = false;
              }
            } else {
              if ( c.wait >= 20 ) {
                  this.givewarning[queueid].class = true;
                  if ( this.generalSettings.notifications.sound && this.generalSettings.notifications.lastNotificationTime >= this.generalSettings.notifications.notificationDelay ) {
                    this.playSound();
                    this.generalSettings.notifications.lastNotificationTime = 0;
                  }
                  break;
              } else {
                  this.givewarning[queueid].class = false;
              }
            }
          } else {
            this.givewarning[queueid].class = false;
          }
        }
      }

      if ( typeof this.queues[ queueid ].calls[ callid-1 ] != 'undefined' ) {
        this.queues[ queueid ].calls[ callid-1 ].wait++;
      }
      this.generalSettings.notifications.lastNotificationTime++;
    });

  }

  playSound() {
    var audio = new Audio('assets/sounds/alert.mp3');
    audio.play();
  }

  getExtension( extension ) {
    let result = extension.split( "@" );
    result = result[0].split( "/" );
    return result[1];
  }

  counter( wait ) {
    let sec_num = parseInt(wait, 10); // don't forget the second param
    let hours   = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = parseInt("0"+hours);}
    if (minutes < 10) {minutes = parseInt("0"+minutes);}
    if (seconds < 10) {seconds = parseInt("0"+seconds);}
    return hours+':'+minutes+':'+seconds;
  }

  eventProcess( event ) {

    if ( typeof event.response != "undefined" && event.response == "login" ) {

      if ( typeof event.status != "undefined" && event.status == "success" ) {
        //console.log( "Loggedin" );
      }
    } else if ( typeof event.type != "undefined" && event.type == "QueueStatus" ) {
      if ( typeof event.event != "undefined" ) {
        if ( event.event == "QueueParams" ) {
          this.queues.push( {
            "name":event.queue,
            "number":event.queue,
            "extensions": [],
            "calls": []
          } );
          this.givewarning.push( { "class":false } );

          this.queueSettings[ event.queue ] = {
                                      "queue":event.queue,
                                      "name":event.queue,
                                      "notifications" : {
                                        "status":true,
                                        "colorStatus":true,
                                        "ringStatus":false,
                                        "color": "#00ffff",
                                        "waittime":300,
                                        "maxpeople":15
                                      }
                                  };

        } else if ( event.event == "QueueMember" ) {
          for ( let quque of this.queues ) {
            if ( quque.number == event.queue ) {
              quque.extensions.push( { "name": event.extension, "extension": event.location } );
            }
          }
        } else if ( event.event == "QueueEntry" ) {
          let queueId = 0;
          for ( let quque of this.queues ) {
            if ( quque.number == event.queue ) {
              quque.calls.push( { "name": event.calleridname, "number": event.calleridnum, "uniqueid": event.uniqueid, "position":event.position, "wait":event.wait } );
              this.getTimer( queueId, this.queues[ queueId ].calls.length );
            }
            queueId++;
          }

        }
      }
      } else if (  typeof event.type != "undefined" && event.type == "QueueJoin"  ) {
        let queueId = 0;
        for ( let quque of this.queues ) {
          if ( quque.number == event.queue ) {
            quque.calls.push( { "name": event.calleridname, "number": event.calleridnum, "uniqueid": event.uniqueid, "position":event.position, "wait":1 } );
            this.getTimer( queueId, this.queues[ queueId ].calls.length );
          }
          queueId++;
        }

      } else if (  typeof event.type != "undefined" && event.type == "QueueLeave"  ) {
        let queueId = 0;
        for ( let quque of this.queues ) {
          if ( quque.number == event.queue ) {
            let callId = 0;
            for ( let call of quque.calls ) {
              if ( call.uniqueid == event.uniqueid ) {
                this.queues[ queueId ].calls.splice(callId, 1);
              }
              callId += 1;
            }
          }
          queueId += 1;
        }
      } else if ( typeof event.type != "undefined" && event.type == "incoming" ) {
        this.callKeys.push( event.uniqueid );
        this.activeCalls[ event.uniqueid ] = { "caller": event.caller, "called": event.called, "uniqueid": event.uniqueid };
      } else if ( typeof event.type != "undefined" && event.type == "outgoing" ) {
        this.callKeys.push( event.uniqueid );
        this.activeCalls[ event.uniqueid ] = { "caller": event.caller, "called": event.called, "uniqueid": event.uniqueid };
      } else if ( typeof event.type != "undefined" && event.type == "hangup" ) {
        let idCall = this.callKeys.indexOf( event.uniqueid );
        if ( idCall > -1 ) {
           this.callKeys.splice( idCall, 1 );
           if ( typeof this.activeCalls[ event.uniqueid ] != "undefined" ) {
             delete this.activeCalls[ event.uniqueid ];
           }
        }
      }
    this.ref.detectChanges();

    function getStatusText( status ) {
      switch( status ) {
        case "0":
          return "Hazır";
        case "1":
          return "Görüşmede";
        case "2":
          return "Meşgul";
        case "4":
          return "Bağlı Değil";
        case "8":
          return "Çalıyor";
        case "16":
          //return "Hazır";
          break;
      }
    }
  }

  settingsAlert( index ) {
    let statusOn = "";
    let statusOff = "";
    if ( this.queueSettings[ index ].notifications.status ) {
      statusOn = "checked";
    } else {
      statusOff = "checked";
    }


    swal({
        title: 'Ayarlar',
        imageUrl: "./assets/img/icons/materialsettings1.png",
        confirmButtonText:  'Kaydet',
        cancelButtonText:  'İptal',
        showCancelButton: true,
        html : '<div class="swal_input_wrapper">'+
                '<div class="label_wrapper mb-2">Bildirimler</div>'+
                '<input type="radio" name="swal2-radio" id="swal2-radio1" class="pt-2" value="1" ' + statusOn + '> Açık '+
                '<input class="pl-2 pt-2" type="radio" id="swal2-radio1" name="swal2-radio" value="0" ' + statusOff + '> Kapalı'+
                '<div class="label_wrapper mt-3">Maksimum Bekleme</div>'+
                '<input class="swal2-input" value="' + this.queueSettings[ index ].notifications.waittime + '" id="swal-range1" type="input" style="width:50%" placeholder="Saniye">'+
                '<div class="label_wrapper mt-2">Maksimum Çağrı</div>'+
                '<input class="swal2-input" value="' + this.queueSettings[ index ].notifications.maxpeople + '" id="swal-range2" type="input" style="width:50%" placeholder="Saniye">'+
                '</div>',
        preConfirm: () => {
          return { "queue": this.queueSettings[ index ].notifications, "status": document.getElementsByName('swal2-radio'), "maxwait": document.getElementById('swal-range1').value, "maxcalls": document.getElementById('swal-range2').value }
        }
    }).then(function ( prompt ) {
        if ( prompt.value ) {
          for (var i = 0, length = prompt.value.status.length; i < length; i++) {
           if ( prompt.value.status[i].checked ) {
             if ( prompt.value.status[i].value == "1" ) {
               prompt.value.queue.status = true;
             } else {
               prompt.value.queue.status = false;
             }
            break;
           }
          }
          prompt.value.queue.waittime = prompt.value.maxwait;
          prompt.value.queue.maxpeople = prompt.value.maxcalls;
        }
    });
  }

}
