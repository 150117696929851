import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard', title: 'Dashboard', icon: 'ft-bar-chart-2', class: '', badge: '', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: false, submenu: []
    },
    {
        path: '', title: 'Phonebook', icon: 'ft-book', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/contacts/contacts', title: 'Contacts', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/contacts/groups', title: 'Groups', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    }, 
    {
        path: '', title: 'Voice Service', icon: 'ft-phone', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/numbers/numbers', title: 'My Numbers', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/numbers/callhistory', title: 'Call History', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Central', icon: 'ft-cloud', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/santral', title: 'General View', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/cdrrecords', title: 'Call History', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/missedcalls', title: 'Missed Calls', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            {
              path: '', title: 'Settings', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
                { path: '/central/settings/extensions', title: 'Extensions Manage.', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/central/settings/inbounds', title: 'Inbound Manage.', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/central/settings/ivrs', title: 'IVR Management', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/central/settings/queues', title: 'Queues', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/central/settings/ringgroups', title: 'Ring Groups', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/central/settings/timeconditions', title: 'Time Conditions', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/central/settings/sounds', title: 'Sound Files', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
              ]
            }
          ] 
    },
    {
        path: '', title: 'Advanced Reports', icon: 'ft-bar-chart-2', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/reports/advanced/extension', title: 'Extension Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/reports/advanced/settings', title: 'Reports Settings', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
          ]
    },
    {
        path: '', title: 'FAX Service', icon: 'ft-printer', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/fax/send', title: 'Send FAX', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/fax/list', title: 'FAX CDR', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/fax/queue', title: 'FAX Queue', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/fax/settings', title: 'Settings', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'SMS Service', icon: 'ft-mail', class: 'has-sub disabled', badge: 'Pasif', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/sms/dashboard', title: 'Dashboard', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/sms/inbox', title: 'Inbox', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/sms/simplesms', title: 'Send Simple SMS', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/sms/multiplesms', title: 'Send Multiple SMS', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/sms/advancedsms', title: 'Send Personalized SMS', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/sms/reports', title: 'Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/sms/blacklist', title: 'Blacklist', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/sms/settings', title: 'Settings', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
    },

    // {
    //     path: '', title: 'CRM Service', icon: 'ft-globe', class: 'has-sub disabled', badge: 'Pasif', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: false, submenu: [
    //         { path: '/crmdetails', title: 'Service Details', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    //     ]
    // },
    {
        path: '', title: 'Internet', icon: 'ft-wifi', class: 'has-sub disabled', badge: 'Pasif', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/internet', title: 'Service Details', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
    },
    {
        path: '', title: 'Call Center', icon: 'icon-earphones-alt', class: 'has-sub disabled', badge: 'Pasif', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/agentlogin', title: 'Agent Login', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/callcenter/reports', title: 'Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '/invoices', title: 'Invoices', icon: 'ft-trending-up', class: '', badge: '', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: false, submenu: []
    },
    //{
        //path: '', title: 'Ürün ve Hizmetler', icon: 'ft-codepen', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: false, submenu: [
            //{ path: '/services', title: 'Hizmetler', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            //{ path: '/products', title: 'Ürünler', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            //{ path: '/balance', title: 'Bakiyem', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
            //{ path: '/invoices', title: 'Invoices', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        //]
    //},
    {
        path: '/documents', title: 'Documents', icon: 'ft-folder', class: '', badge: '', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: false, submenu: []
    },
    {
        path: '', title: 'Support', icon: 'ft-life-buoy', class: 'has-sub ', badge: 'Pasif', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/support/new', title: 'Create Ticket', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/support/list', title: 'Tickets', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'API Integrations', icon: 'icon-puzzle', class: 'has-sub ', badge: '', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/api/websocket', title: 'Web Socket', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/api/pushservice', title: 'PUSH Service', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/api/restservice', title: 'REST Service', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: 'https://yardim.kobikom.com.tr', title: 'Help', icon: 'ft-help-circle', class: '', badge: '', badgeClass: 'badge badge-pill badge-secondary float-right mr-1 mt-1', isExternalLink: true, submenu: []
    },
];
