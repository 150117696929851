import { Routes, RouterModule } from '@angular/router';
//Route for content layout with sidebar, navbar and footer.

export const ADMIN_ROUTES: Routes = [
  {
    path: 'admin',
    loadChildren: './admin/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'admin/dashboard',
    loadChildren: './admin/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'admin/customers',
    loadChildren: './admin/customers/customers.module#CustomersModule'
  },
  {
    path: 'admin/newcustomer',
    loadChildren: './admin/newcustomer/newcustomer.module#NewcustomerModule'
  },
  {
    path: 'admin/centrallist',
    loadChildren: './admin/centrallist/centrallist.module#CentralListModule'
  },
  {
    path: 'admin/oldcustomer',
    loadChildren: './admin/oldcustomer/oldcustomer.module#OldCustomerModule'
  },
  {
    path: 'admin/centralcreate',
    loadChildren: './admin/centralcreate/centralcreate.module#CentralAddModule'
  }
];
