import { Observable } from 'rxjs/Rx';
import { Response } from '@angular/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {
  token: string;
  success: string;
  result: any;

  constructor( private router: Router ) {

  }

  signupUser(vkntckn: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(eposta: string, password: string, verificationCode: string) {
    return Observable.fromPromise(new Promise((resolve, reject) => {
      //console.log( "E-Posta: " + eposta + " Password: " + password );
      var URL = "https://panelapi.kobikom.com.tr/loginNew";
      var params = "usermail=" + eposta +"&userpass=" + password + "&recaptcha=" + verificationCode;

      const req = new XMLHttpRequest();
      req.open( "POST", URL, false );
      req.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

      req.onreadystatechange = function() {//Call a function when the state changes.
        if( req.status == 200) {
          var response = JSON.parse( req.response );
          if ( response.status == 1 ) {
            localStorage.setItem( 'currentUser', response.token );
            //localStorage.getItem( 'currentUser' );
            resolve( response );
          } else {
            reject( response );
          }
        } else {
          reject( req.response );
        }
      }
      req.send(params);

      //return this.token;
      //your code for checking credentials and getting tokens for for signing in user
      // here you can check if user is authenticated or not through his token
      /*this.getToken((data) => {
        //console.log(data);
        if ( data.status == 1 ) {
          return true;
        } else {
          return false;
        }
      });*/
    }));
  }

  logout() {
     localStorage.removeItem('currentUser');
  }

  getToken(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', 'https://panelapi.kobikom.com.tr/login');

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    req.send();
    return this.token;
  }

  checkuser(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', 'https://panelapi.kobikom.com.tr/isloggedin');
    req.setRequestHeader( 'Authorization', 'Bearer ' + localStorage.getItem('currentUser') );

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    req.send();
    return this.success;
  }

  isAuthenticated( roles ) {
    //console.log( this.parseJwt( localStorage.getItem('currentUser') ) );
    if ( localStorage.getItem('currentUser') ) {
      this.checkuser((data) => {
        this.result    = data;
        if ( this.result.success !== true ) {
          this.router.navigateByUrl('pages/login');
          return false;
        }
      });

      if( typeof roles[0] != "undefined" && roles[0] == "SuperAdmin" ) {

        let userType = this.parseJwt( localStorage.getItem('currentUser') );

        if ( userType.prv == "Admin" ) {
          return true;
        }

      } else {
        return true;
      }

    }
    this.router.navigateByUrl('pages/login');
    return false;
  }

  parseJwt( token ) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
  };
}
