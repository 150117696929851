import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import { WebsocketService } from './websocket.service';

const CHAT_URL = 'wss://api.kobikom.com.tr:8098/';

export interface Message {
	command?: string,
	password?: string,
	crmid?: string,
  type?: string,
  response?: string,
  extension?: string,
  message?: string
}

@Injectable()
export class ChatService {
	public messages: Subject<Message>;

	constructor(wsService: WebsocketService) {
		this.messages = <Subject<Message>>wsService
			.connect(CHAT_URL)
			.map((response: MessageEvent): Message => {
				let data = JSON.parse(response.data);
				return data;
			});
	}

}
