import { AdminRouteInfo } from './admin-sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: AdminRouteInfo[] = [

    { path: '/admin/dashboard', title: 'Dashboard', icon: 'ft-activity', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/admin/customers', title: 'Customers', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/admin/centrallist', title: 'Central List', icon: 'ft-cloud', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/admin/oldcustomer', title: 'Old Customers', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
];
