import { Injectable } from '@angular/core';
import * as Rx from 'rxjs/Rx';

@Injectable()
export class WebsocketService {
  constructor() { }

  private subject: Rx.Subject<MessageEvent>;

  public connect(url): Rx.Subject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
      //console.log("Successfully connected: " + url);
    }
    return this.subject;
  }

  private create(url): Rx.Subject<MessageEvent> {
    let ws = new WebSocket(url);
    let loginConf = {
      "command": "login",
      "token": localStorage.getItem('currentUser')
    };

    ws.onopen = () => {
      ws.send( JSON.stringify( loginConf ) );
    }

    ws.onclose = () => {
      alert( "Server ile olan bağlantı kesildi. Lütfen internet bağlantınızı kontrol edip sayfayı yenileyin." );
      ws = new WebSocket(url);
      ws.send( JSON.stringify( loginConf ) );
    }

    ws.onerror = () => {
      alert( "Server ile olan bağlantı kesildi. Lütfen internet bağlantınızı kontrol edip sayfayı yenileyin." );
      ws = new WebSocket(url);
      ws.send( JSON.stringify( loginConf ) );
    }

    let observable = Rx.Observable.create(
  	(obs: Rx.Observer<MessageEvent>) => {
  		ws.onmessage = obs.next.bind(obs);
  		ws.onerror = obs.error.bind(obs);
  		ws.onclose = obs.complete.bind(obs);
  		return ws.close.bind(ws);
  	})

    let observer = {
  		next: (data: Object) => {
  			if ( ws.readyState === 1 ) {
  				ws.send(JSON.stringify(data));
  			} else {
          //console.log( "WebSocket State: " + ws.readyState );
          //ws.send(JSON.stringify(data));
        }
  		}
  	}
  	return Rx.Subject.create(observer, observable);
  }

}
