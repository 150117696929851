import { Component, AfterViewChecked } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../../shared/auth/auth.service';

import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements AfterViewChecked {
    currentLang = 'tr';
    toggleClass = 'ft-maximize';
    placement = 'bottom-right'
    public isCollapsed = true;
    profileInfo = {};

    constructor( private router: Router, public translate: TranslateService, private authService: AuthService ) {
        const browserLang: string = translate.getBrowserLang();
        translate.use(browserLang.match(/en|tr/) ? browserLang : 'tr');

        this.fetch((data) => {
          this.profileInfo = data;
        });
    }

    fetch(cb) {
      const req = new XMLHttpRequest();

      req.open( 'GET', 'https://panelapi.kobikom.com.tr/getProfileInfo' );
      req.setRequestHeader( 'Authorization', 'Bearer ' + localStorage.getItem('currentUser') );

      req.onload = () => {
        cb(JSON.parse(req.response));
      };

      req.send();
    }

    ngAfterViewChecked() {
        // setTimeout(() => {
        //     var wrapperDiv = document.getElementsByClassName("wrapper")[0];
        //     var dir = wrapperDiv.getAttribute("dir");
        //     if (dir === 'rtl') {
        //         this.placement = 'bottom-left';
        //     }
        //     else if (dir === 'ltr') {
        //         this.placement = 'bottom-right';
        //     }
        // }, 3000);
    }

    ChangeLanguage(language: string) {
        this.translate.use(language);
    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        }
        else
            this.toggleClass = 'ft-maximize'
    }

    Logout() {
      this.authService.logout();
      this.router.navigate(['/pages/login']);
      return false;
    }
}
